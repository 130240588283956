import logo from './logo.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"/>
        <title>Energy Saving Services (ESS) - Under Construction!</title>
        <h1>Energy Saving Services</h1>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          <code>website under construction!</code>
        </p>
        <a
        href="https://wa.me/85512320002"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
        >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
      </header>
    </div>
  );
}

export default App;
